export default function () {

	$('.js-takeover-link:not(.js-takeover-link-done)').each(function () {
		var link = $(this);
		var id = $('a' + link.attr('href'));

		if (!id.length) return false;

		// click
		link.off('click').on('click', function () {
			id.click();
			return false;
		});

		// done
		link.addClass('js-takeover-link-done');
	});

}
